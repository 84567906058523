"use strict";

import { Component } from "react";
import PropTypes from "prop-types";
import { Link, IndexLink } from "react-router";
import moment from "moment";

import DownForMaintenanceModal from "../../components/Widgets/header/DownForMaintenanceModal.react";
import ImgResized from "../../components/Widgets/ImgResized.react";
import Alert from "../../components/Widgets/Alert/Alert.react";

import SideNav from "./OffCanvas.react";
import AuthActions from "../../actions/AuthActions";
import UserActions from "../../actions/UserActions";
import UserStore from "../../stores/UserStore";
import AuthStore from "../../stores/AuthStore";
import ChatStore from "../../stores/ChatStore";

import { getConfig } from "../../utils/Env";

import SetPassword from "../../components/Widgets/header/SetPassword.react";
import Popup from "../../components/Widgets/Popup.react";

import "./Header.scss";
import Analytics from "../../utils/Analytics";

const VERSION_POLL_INTERVAL = 15 * 60; // 15 minutes in seconds

export default class Header extends Component {
    static propTypes = {
        showSignIn: PropTypes.bool,
        showSignUp: PropTypes.bool,

        showNavigation: PropTypes.bool,
    };

    static defaultProps = {
        showSignIn: true,
        showSignUp: true,
        showNavigation: true,
    };

    static contextTypes = {
        isMobile: PropTypes.bool,
        router: PropTypes.object,
        location: PropTypes.object,
        breakpoint47em: PropTypes.bool,
        showLoginForm: PropTypes.func,
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            user: UserStore.getUser(),
            unreads: ChatStore.getUnreads(),

            isDocumentHidden: null, // null so it fires handleVisibilityChange when the component is mounted
            latestVersion: getConfig("build_number"),
            downForMaintenance: null,
        };
    }

    componentDidMount = () => {
        UserStore.addChangeListener(this.onUserStoreChange);
        ChatStore.addChangeListener(this.onChatStoreChange);

        if (process.browser) {
            document.addEventListener(this.getVisibilityChangeEventName(), this.handleVisibilityChange);
            this.startVersionPollInterval();
        }
    };

    componentWillUnmount = () => {
        UserStore.removeChangeListener(this.onUserStoreChange);
        ChatStore.removeChangeListener(this.onChatStoreChange);

        if (process.browser) {
            document.removeEventListener(this.getVisibilityChangeEventName(), this.handleVisibilityChange);
        }
    };

    onChatStoreChange = () => {
        const unreads = ChatStore.getUnreads();

        this.setState({ unreads });
    };

    onUserStoreChange = () => {
        const user = UserStore.getUser();

        this.setState({
            user: UserStore.getUser(),
            loginIsOpen: user ? false : this.state.loginIsOpen,
        });
    };

    isDocumentHidden = () => {
        // Set the name of the hidden property and the change event for visibility
        var hidden;

        if (typeof document.hidden !== "undefined") {
            // Opera 12.10 and Firefox 18 and later support
            hidden = "hidden";
        } else if (typeof document.msHidden !== "undefined") {
            hidden = "msHidden";
        } else if (typeof document.webkitHidden !== "undefined") {
            hidden = "webkitHidden";
        }

        return (document && document[hidden]) || false;
    };

    versionPollInterval = null;
    versionPollPromise = null;
    disconnectTimeout = null;

    // scheduleDisconnect = () => {
    //     this.cancelDisconnect();
    //     this.disconnectTimeout = setTimeout(async () => {
    //         await ChatStore.disconnect();
    //         console.log("Disconnected after 5 minutes away");
    //     }, 300000);
    // };

//TO DO: set back to 300000
    scheduleDisconnect = () => {
        this.cancelDisconnect();
        this.disconnectTimeout = setTimeout(async () => {
            await ChatStore.disconnect();
            console.log("Disconnected after 5 minutes away");
        }, 1000);
    };


    cancelDisconnect = () => {
        if (this.disconnectTimeout) {
            clearTimeout(this.disconnectTimeout);
            this.disconnectTimeout = null;
            console.log("User returned, canceling disconnect");
        }
    }

    stopVersionPollInterval = () => {
        if (this.versionPollInterval) {
            clearInterval(this.versionPollInterval);
            this.versionPollInterval = null;
        }

        this.versionPollPromise = null;
    };

    startVersionPollInterval = () => {
        this.stopVersionPollInterval();

        this.pollForVersion(true);
        this.versionPollInterval = setInterval(this.pollForVersion, VERSION_POLL_INTERVAL * 1000);
    };

    pollForVersion = () => {
        const { latestVersion, downForMaintenance } = this.state;
        // Don't check for build numbers in dev.
        if (getConfig("build_number") === "local" || latestVersion === "local") {
            return;
        }

        // Do not poll if we're already out of date.
        if (!downForMaintenance && latestVersion != getConfig("build_number")) {
            return;
        }

        // Do not make multiple simultaneous requests
        if (this.versionPollPromise) {
            return;
        }

        try {
            this.versionPollPromise = AuthStore.fetch("https://" + getConfig("self_host") + "/version", {}, true).then(
                (success) => {
                    this.versionPollPromise = null;

                    if (downForMaintenance && !success.down) {
                        location.reload();
                    } else {
                        this.setState({ latestVersion: success.version, downForMaintenance: success.down });
                    }
                },
                (error) => {
                    this.versionPollPromise = null;

                    let found = null;

                    if (error && error.text && (found = error.text.match(/down for maintenance: ([.\s\S]*?) EOM/))) {
                        this.setState({ downForMaintenance: found[1] });
                    }
                },
            );
        } catch (exp) {
            this.versionPollPromise = null;
        }
    };

    isAppHidden = () => {
        // Set the name of the hidden property and the change event for visibility
        var hidden;

        if (typeof document.hidden !== "undefined") {
            // Opera 12.10 and Firefox 18 and later support
            hidden = "hidden";
        } else if (typeof document.msHidden !== "undefined") {
            hidden = "msHidden";
        } else if (typeof document.webkitHidden !== "undefined") {
            hidden = "webkitHidden";
        }

        return document && document[hidden] ? true : false;
    };

    /**
     * HAACk, *cough*cough*.  Wooo! That's some good shit right there. *HAAAACKC*burp*.
     *
     * @return {[type]} [description]
     */
    getVisibilityChangeEventName = () => {
        var eventName;

        if (typeof document.hidden !== "undefined") {
            // Opera 12.10 and Firefox 18 and later support
            eventName = "visibilitychange";
        } else if (typeof document.msHidden !== "undefined") {
            eventName = "msvisibilitychange";
        } else if (typeof document.webkitHidden !== "undefined") {
            eventName = "webkitvisibilitychange";
        }

        return eventName;
    };

    onAppStateChange = (state) => {
        const { isActive = true } = state || {};

        if (isActive) {
            this.startVersionPollInterval();

            // Synchronize a buttload of stuff. This also reconnects to the chat server.
            UserActions.synchronizeUser();

            this.cancelDisconnect();
        }
    };

    handleVisibilityChange = () => {
        if (!process.browser) {
            return;
        }

        const isAppHidden = this.isAppHidden();

        if (isAppHidden) {
            this.scheduleDisconnect();
        }

        this.onAppStateChange({ isActive: !isAppHidden });
    };

    logout = () => {
        this.setState({ popover: false, user: false, capabilities: {} });
        UserActions.logout();
    };

    closeModal = () => {
        this.setState({ loginIsOpen: false });
    };

    logout = () => {
        AuthActions.deauthorize();

        this.context.router.push("/");
    };

    onCompleteSignUpOrIn = (user) => {
        // Is this user already a practice member? Forward them back to the recommend dialog
        if (user.links && user.links.practice && user.dietitian) {
            return;
        }

        // Otherwise, forward the user to the PRO onboarding second page
        const { router } = this.context;
        router.push("/new-account");
    };

    startFreeTrial = () => {
        const { showLoginForm } = this.context;

        showLoginForm({
            defaultMode: "sign-up",
            hideIDPs: true,
            signupLinkSwitchesModes: true,
            onCompleteSignUp: this.onCompleteSignUpOrIn,
            onCompleteSignIn: this.onCompleteSignUpOrIn,
            signUpProfile: { completed: ["pro-register"] },
            offModalCta: "You’re one more step from profitable meal planning!",
            signUpCTA: "Sign Up Free",
            signupFormClassNames: "pro-register-form",
            signupFormTitleText: "Create an account to access our full meal planning features.",
            brochureTitleText: (
                <span>EatLove’s Nutrition Intelligence&trade; helps your clients with healthy eating habits.</span>
            ),
            brochurePointsText: [
                "Personalized meal recommendations that keep clients on-track and engaged with their health and wellness goals",
                "Supports 30 of the most common medical conditions and lifestyle goals",
                "More than 6000 delicious recipes from food and nutrition experts",
                "Automatic grocery lists with optional delivery where available",
                "Position clients to feel confident in their food choices by leveraging EatLove as a nutrition education platform",
                "Smart restaurant swaps to empower your clients on-the-go",
            ],
        });
    };

    openLoginModal = (loginFormOptions = {}) => {
        const { showLoginForm } = this.context;

        Object.assign(loginFormOptions, {
            formSubtitleText: "Sign in to access your patient's information and recommended meal plans.",
            hideIDPs: true,
            signupLinkSwitchesModes: true,
            onCompleteSignUp: this.onCompleteSignUpOrIn,
            onCompleteSignIn: this.onCompleteSignUpOrIn,
            signUpProfile: { completed: ["pro-register"] },
            signupFormTitleText: "Create an account to access our full meal planning features.",
            brochureTitleText: (
                <span>EatLove’s Nutrition Intelligence&trade; helps your clients with healthy eating habits.</span>
            ),
            brochurePointsText: [
                "Personalized meal recommendations that keep clients on-track and engaged with their health and wellness goals",
                "Supports 30 of the most common medical conditions and lifestyle goals",
                "More than 6000 delicious recipes from food and nutrition experts",
                "Automatic grocery lists with optional delivery where available",
                "Position clients to feel confident in their food choices by leveraging EatLove as a nutrition education platform",
                "Smart restaurant swaps to empower your clients on-the-go",
            ],
        });

        showLoginForm(loginFormOptions);
    };

    renderNetworkOrVersionNotifier = () => {
        const { isDisconnected } = this.props;
        const { latestVersion } = this.state;

        if (isDisconnected) {
            return (
                <div key="ver" className="version-notifier">
                    <Alert
                        iconType="no-network"
                        type="error no-network"
                        description={"No network. Please check your internet connection."}
                        onClickButton={() => location.reload(true)}
                        buttonText="reload"
                    />
                </div>
            );
        }

        if (latestVersion != getConfig("build_number")) {
            const reloadFunc = () => {
                Analytics.reloadForUpgrade({
                    "Old Version": latestVersion,
                    "New Version": getConfig("build_number"),
                }).finally(() => location.reload(true));
            };

            return (
                <div key="ver" className="version-notifier">
                    <Alert
                        iconType="no-icon"
                        type="question"
                        description={"There's a new upgrade for EatLove. Please click to activate."}
                        onClickButton={() => reloadFunc()}
                        buttonText="reload"
                    />
                </div>
            );
        }

        return null;
    };

    renderNavigationOrLogin = () => {
        const { isMobile } = this.context;
        const { user, unreads } = this.state;
        const { showSignIn, showNavigation } = this.props;

        let unread = (unreads || []).reduce((cv, chat) => cv + chat.unread, 0);
        let total = unread > 9 ? "9+" : unread;

        const badge = total ? <div className="badge">{total}</div> : null;

        // Case #1: Not signed in at all.
        if (!user) {
            let links = [];

            if (showSignIn) {
                links.push(
                    <button key="sign-in" className="signin-btn" onClick={() => this.openLoginModal()}>
                        Sign In
                    </button>,
                );
            }

            return <div className="utility-links">{links}</div>;
        }

        // Case #2: Signed in, but not a dietitian in a practice
        if (user && !(user.links && user.links.practice && user.dietitian)) {
            return (
                <div className="utility-links">
                    <button key="sign-in" className="signin-btn" onClick={this.logout}>
                        Sign Out
                    </button>
                </div>
            );
        }

        // Case #3: Signed in, and a dietitian
        const wwwUrl = "https://" + getConfig("www_host");

        const content = [];

        const isChatEnabled = !user.practice.inhibit_jabber && !user?.dietitian?.inhibit_jabber;
        const showGrocery = user?.features?.auto_populate_groceries?.run_day;

        if (showNavigation) {
            content.push(
                <div key="nav" className="navigation-links">
                    <IndexLink to="/" activeClassName="active">
                        <i className="icon-dashboard1" />
                        <span>Dashboard</span>
                    </IndexLink>
                    <Link to="/clients" className="header-patients-link" activeClassName="active">
                        <i className="icon-patients1" />
                        <span>{user.practice_type === "fitness" ? "Clients" : "Patients"}</span>
                    </Link>
                    {isChatEnabled ? (
                        <Link to="/messages" className="header-messages-link" activeClassName="active">
                            <i className="icon-messages" />
                            {badge}
                            <span>Messages</span>
                        </Link>
                    ) : null}
                    <Link to="/learn" className="header-learn-link" activeClassName="active">
                        <i className="icon-learn" />
                        <span>Learn</span>
                    </Link>
                    <Link to="/toolkit" className="header-toolkit-link" activeClassName="active">
                        <i className="icon-toolkit1" />
                        <span>Toolkit</span>
                    </Link>
                    {!isMobile ? (
                        <Link to="/nutrition-academy" className="header-toolkit-link" activeClassName="active">
                            <i className="icon-lightbulb" />
                            <span>Academy</span>
                        </Link>
                    ) : null}
                    {!isMobile && showGrocery ? (
                    <Link to="/export-grocery" className="heade-export-grocery-link" activeClassName="active">
                        <i className="icon-groceries" />
                        <span>Grocery</span>
                    </Link>
                    ) : null}
                </div>,
            );
        }

        if (showNavigation) {
            content.push(this.renderNetworkOrVersionNotifier());
        }

        if (showNavigation) {
            const practiceActiveUntil = moment(user.practice.active_until);
            const remaining = practiceActiveUntil.diff(moment(), "days");

            content.push(
                <div key="utils" className="utility-links">
                    {user.practice.subscription_plan === "free_trial" && practiceActiveUntil.isAfter(moment()) ? (
                        <Link className="header-freetrial-link" to="/membership">
                            <div className="free-trial-remaining">
                                <div>Free trial:</div>
                                <div>
                                    {remaining} {remaining === 1 ? "day" : "days"} left
                                </div>
                            </div>
                        </Link>
                    ) : null}
                    <a href={wwwUrl} target="_blank" className="go-to-consumer">
                        <button key="Consumer" title="EatLove">
                            <i className="icon-logo2">
                                <span className="path1" />
                                <span className="path2" />
                            </i>
                        </button>
                    </a>

                    {user.is_password_expired ? (
                        <Popup
                            alwaysKeepOpen={true}
                            positionClassName="el-popup-top-right"
                            button={<SideNav location={this.props.location} showGrocery={showGrocery && isMobile}/>}
                        >
                            <SetPassword expired={user.is_password_expired} />
                        </Popup>
                    ) : (
                        <SideNav location={this.props.location} showGrocery={showGrocery && isMobile}/>
                    )}
                </div>,
            );
        }

        return content;
    };

    renderDownForMaintenaceModal = () => {
        const { downForMaintenance } = this.state;

        if (!downForMaintenance) {
            return;
        }

        return <DownForMaintenanceModal message={downForMaintenance} />;
    };

    /** Avatar! Know that Brittania has entered into a new age of enlightenment! Know that the time has finally come for
    the one true king to take his place at the head of his people! Under my guidance Brittania will flourish, and all the
    people shall rejoice! For their new Guardian.

    Know that you too shall kneel before me Avatar, you too shall soon respect my authority. For I shall be your protector,
    your guardian, and, your master! Mwahahahahah! */
    renderAvatar = () => {
        const { user } = this.state;
        const { location } = this.context;

        if (location.pathname == "/new-account") {
            return;
        }

        if (!user) {
            return;
        }

        let avatar = user.gender === "male" ? <i className="icon-male3" /> : <i className="icon-female3" />;

        if (user.image) {
            avatar = <ImgResized src={user.image} width={120} height={120} />;
        } else if (user.name) {
            const words = user.name.split(" ");
            const firstInitial = (words[0] && words[0][0]) || ""; // first letter of first word
            const lastInitial = (words.length > 1 && words[words.length - 1] && words[words.length - 1][0]) || ""; // first letter of last word

            avatar =
                firstInitial || lastInitial ? (
                    <em>
                        {firstInitial}
                        {lastInitial}
                    </em>
                ) : (
                    avatar
                );
        }

        return (
            <Link to="/my-account/settings" className="avatar">
                {avatar}
            </Link>
        );
    };

    render = () => {
        const { user } = this.state;

        return (
            <header className="pro-header">
                <section className="header-container">
                    <section className="logo-container">
                        <Link to="/">
                            <span className="assistive-text">EatLove Home Page</span>PRO
                        </Link>
                        {user && user.practice_type === "fitness" ? <div className="tagline">Fitness</div> : null}
                        {user && user.practice_type === "dietetics" ? <div className="tagline">Medical</div> : null}
                        {!user ? <div className="tagline">Nutrition Intelligence</div> : null}
                    </section>

                    {this.renderAvatar()}
                    {this.renderNavigationOrLogin()}
                </section>
                {this.renderDownForMaintenaceModal()}
            </header>
        );
    };
}
